import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor() {}

    public static Routes = {

        Home(): any[] {
            return ['/'];
        },

        AboutUs(): any[] {
            return ['/about-us'];
        },

        Contact(): any[] {
            return ['/contact'];
        },

        Newsletter(): any[] {
            return['/newsletter']
        },

        CustomerSupport(): any[] {
            return ['/customer-support'];
        },

        LegalNotices(): any[] {
            return ['/legal-notices'];
        },

        ProjectManagement(): any[] {
            return ['/project-management'];
        },

        TermsAndConditions(): any[] {
            return ['/terms-and-conditions'];
        },

        Qoezion(): any[] {
            return ['/qoezion'];
        },

        QoezionRecruit(): any[] {
            return ['/qoezion/recruit'];
        },

        QoezionPlan(): any[] {
            return ['/qoezion/plan'];
        },

        QoezionCommunication(): any[] {
            return ['/qoezion/communication'];
        },

        QoezionCartography(): any[] {
            return ['/qoezion/map'];
        },

        QoezionOperational(): any[] {
            return ['/qoezion/operational'];
        },

        Qomeet(): any[] {
            return ['/qomeet'];
        },

        Customers(): any[] {
            return ['/customers'];
        },

        SuccessStory(): any[] {
            return ['/success-story'];
        },

    }
}
